<template>
  <v-app>

    <div v-if="this.signedIn" class="practiceContent">

      <v-overlay :absolute="absolute" :value="overlay" color="#fff" opacity="1">
        <v-card class="mx-auto mb-4"  color="white" elevation="0">
          <div class="grid-container">
            <div>
              <div class="resultIconContainer" style="border-radius:50%" :class="getBackgroundColour(this.result)">
                <v-icon dark x-large color="#f28482" v-if="this.result != 'Correct'">
                  mdi-close
                </v-icon>

                <v-icon dark x-large color="#90be6d" v-if="this.result == 'Correct'">
                  mdi-check
                </v-icon>
              </div>
            </div>
            <div>
              <v-card-text>
                <h1>
                  {{ this.result }}
                </h1>
                <div class="text--primary">
                  <b>{{ this.wrongRecap[0] }}</b>
                </div>
                <div class="text--primary">
                  {{ this.wrongRecap[1] }}
                </div>
                <div class="text--primary">
                  {{ this.wrongRecap[2] }}
                </div>
              </v-card-text>
            </div>
            <div>
              <v-btn dark x-large color="#90be6d" @click="overlay = false">
                Next question
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-overlay>

      <div class="progressContainer">
        <div class="scoreBar">
          <v-progress-linear v-model="score" rounded height="10" color="green lighten-2"></v-progress-linear>
        </div>
        <div class="livesRemaining">
          <span v-for="(life, index) in lives" :key="`life-${index}`"><Emoji name='heart'/>&nbsp;&nbsp;</span>
        </div>
      </div>

      <div v-if="!this.loading" class="task">

        <h3>{{ this.question }}</h3>
        <h2>{{ this.russianSentence }}</h2>
        <h3>{{ this.english }}</h3>
        <br>

        <div v-if="this.keyboardInput == true && this.taskType != 2" class="keyboardInput">
          <v-text-field outlined autocomplete="off" elevation='0' v-model="keyboardEntered" v-on:keyup.enter="checkAnswer(keyboardEntered)"></v-text-field>
        </div>

        <div v-if="(this.taskType == 1 || this.taskType == 3 || this.taskType == 4 || this.taskType == 5) && this.keyboardInput == false">
          <v-btn v-if="this.shuffledButtons[0] != 'DUPLICATE'" class="ma-2" x-large color="#90be6d" elevation=0 dark @click="checkAnswer(shuffledButtons[0])">{{ shuffledButtons[0] }}</v-btn>
          <v-btn v-if="this.shuffledButtons[1] != 'DUPLICATE'" class="ma-2" x-large color="#00b4d8" elevation=0 dark @click="checkAnswer(shuffledButtons[1])">{{ shuffledButtons[1] }}</v-btn>
          <v-btn v-if="this.shuffledButtons[2] != 'DUPLICATE'" class="ma-2" x-large color="#ffbe0b" elevation=0 dark @click="checkAnswer(shuffledButtons[2])">{{ shuffledButtons[2] }}</v-btn>
          <v-btn v-if="this.shuffledButtons[3] != 'DUPLICATE'" class="ma-2" x-large color="#e73265" elevation=0 dark @click="checkAnswer(shuffledButtons[3])">{{ shuffledButtons[3] }}</v-btn>
          <v-btn v-if="this.shuffledButtons[4] != 'DUPLICATE'" class="ma-2" x-large color="#ada7c9" elevation=0 dark @click="checkAnswer(shuffledButtons[4])">{{ shuffledButtons[4] }}</v-btn>
          <v-btn v-if="this.shuffledButtons[5] != 'DUPLICATE'" class="ma-2" x-large color="#403d39" elevation=0 dark @click="checkAnswer(shuffledButtons[5])">{{ shuffledButtons[5] }}</v-btn>
        </div>

        <div v-if="(this.taskType == 6)">
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#ffbe0b" elevation=0 @click="checkAnswer('masculine')">Masculine</v-btn><br>
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#90be6d" elevation=0 dark @click="checkAnswer('feminine')">Feminine</v-btn><br>
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#00b4d8" elevation=0 dark @click="checkAnswer('neuter')">Neuter</v-btn> <br><br>
        </div>

        <div v-if="(this.taskType == 7 || this.taskType == 8)">
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#90be6d" elevation=0 @click="checkAnswer('yes')">Yes</v-btn><br>
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#e73265" elevation=0 dark @click="checkAnswer('no')">No</v-btn><br>
        </div>

        <div v-if="(this.taskType == 2)">
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#ffbe0b" elevation=0 @click="checkAnswer(sentencesToFitWord[0])">{{ this.sentencesToFitWord[0] }}</v-btn><br>
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#90be6d" elevation=0 dark @click="checkAnswer(sentencesToFitWord[1])">{{ this.sentencesToFitWord[1] }}</v-btn><br>
          <v-btn class="chooseWordToFitSentence ma-2" x-large color="#00b4d8" elevation=0 dark @click="checkAnswer(sentencesToFitWord[2])">{{ this.sentencesToFitWord[2] }}</v-btn> <br><br>
        </div>

      </div>

      <div style="margin-top:25vh" v-if="this.loading">
        <v-fade-transition>
          <v-progress-circular
            indeterminate
            color="#90be6d"
          ></v-progress-circular>
        </v-fade-transition>
      </div>

      <div v-if="this.taskType != 2 && this.taskType != 6 && this.taskType != 7 && this.taskType != 8"  class="changeInputContainer">
        <v-btn large color="primary" @click="changeInput()">Change Input</v-btn>
      </div>

    </div>

  </v-app>
</template>

<style scoped>
  h1 {
    color: #000;
    font-size: 3em;
    padding: 20px;
  }

  i {
    text-transform: lowercase;
  }

  .caseButtonIcon {
    font-size: 1.6em;
    color: rgba(0,0,0,0.7);
    padding: 0;
    margin: 0;
    margin-right: 20px;
    opacity: 0;
  }

  .practiceContent {
    width: 1000px;
    margin: 40px auto;
  }

  .task {
    margin: 60px 0 0 0;
  }

  .chooseWordToFitSentence {
    text-transform: none;
    color: #fff;
  }

  .scoreBar {
    height: 40px;
    width: calc(100% - 100px);
    display:inline-flex;
    float:left;
    align-items: center;
  }

  .livesRemaining {
    height: 40px;
    width: auto;
    display:inline-flex;
    float:right;
    align-items: center;
  }

  .progressContainer {
    height: 40px;
    align-items: center;
  }

  .changeInputContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    bottom: 100px;
  }

  .keyboardInput {
    width: 300px;
    margin: auto;
  }

  .resultIconContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    float:left;
    margin: 0;
    display:inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }

  .colour-green {
    background-color: #d8f3dc !important;
  }

  .colour-red {
    background-color: #f5cac3 !important;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    padding: 0px 20px;
  }

  .grid-container > div {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
</style>



<style scoped>
  @media screen and (max-width: 1024px) {

    .practiceContent {
      max-width: 100vw;
      width: 100vw;
      margin: 0px auto;
      padding: 10px;
    }

  }
</style>










<script>

  import {db} from '../firebase/db'
  import firebase from 'firebase';
  import Emoji from '@/components/Emoji.vue'

  export default{
    title: "Practice",
    props: ['lesson'],
    components: {
      Emoji
    },
    data(){
      return {
        signedIn: false,
        uid: "",
        username: "",
        Sentences: [],
        newItem: "",
        sentence: [],
        word: [],
        result: "",
        score: 0,
        question: "",
        russianSentence: "",
        english: "",
        numberOfWords: 0,
        numberOfSentences: 0,
        numberOfMasculineWords: 0,
        numberOfFeminineWords: 0,
        numberOfNeuterWords: 0,
        numberOfSentencesAdjectives: 0,
        numberOfAdjectives: 0,
        correctAnswer: "",
        sentencesToFitWord: [],
        taskType: "",
        taskOrder: [],
        waitForAnswer: true,
        questionNumber: 0,
        lessonsCompleted: [],
        lessonsCompletedAdjectives: [],
        questionPossibilities: [],
        questionPossibilitiesAdjectives: [],
        task2countsMasc: [],
        task2countsFem: [],
        task2countsNeut: [],
        lives: 3,
        questions: [],
        caseConditional: "",
        genderConditional: "",
        keyboardInput: false,
        keyboardEntered: "",
        absolute: true,
        overlay: false,
        shuffledButtons: [],
        wrongRecap: [],
        loading: true
      }
    },
    methods: {

      getBackgroundColour(result) {
        if (result == 'Correct') {
          return 'colour-green';
        } else {
          return 'colour-red';
        }
      },

      selectRandomCombination() {
        var randomC = Math.floor((Math.random() * this.questionPossibilities.length));
        var caseConditional = this.questionPossibilities[randomC]

        var randomG = Math.floor((Math.random() * caseConditional.length));
        var genderConditional = caseConditional[randomG][1];
        caseConditional = caseConditional[0][0];

        this.caseConditional = caseConditional;
        this.genderConditional = genderConditional;
        return [caseConditional, genderConditional]
      },

      selectRandomCombinationAdjective() {
        var randomq = Math.floor((Math.random() * this.questionPossibilitiesAdjectives.length));
        var caseConditional = this.questionPossibilitiesAdjectives[randomq]

        var randomw = Math.floor((Math.random() * caseConditional.length));
        var genderConditional = caseConditional[randomw][1];
        caseConditional = caseConditional[0][0];

        this.caseConditional = caseConditional;
        this.genderConditional = genderConditional;
        return [caseConditional, genderConditional]
      },

      checkAnswer(input) {

        this.loading = true;
        this.overlay = !this.overlay;

        input = (this.taskType != 2) ? input.toLowerCase() : input;

        var wordType = (this.taskType == 4 || this.taskType == 5) ? "Adj" : "Noun";

        if (this.taskType == 6) {
          this.questions.push([this.question, this.russianSentence, this.english, this.correctAnswer, input, 'nom', 'IdentifyGender', wordType])
        } else {
          this.questions.push([this.question, this.russianSentence, this.english, this.correctAnswer, input, this.caseConditional, this.genderConditional, wordType])
        }

        if (this.correctAnswer == input && this.score < 100) {
          this.score += 10
          this.result = "Correct"
          this.wrongRecap = []
        } else if (this.correctAnswer != input) {
          this.result = "Wrong"
          this.wrongRecap[0] = this.question
          this.wrongRecap[1] = "You answered: " + input
          this.wrongRecap[2] = "The correct answer was: " + this.correctAnswer
          this.lives--;
          if (this.lives == 0) {
            //failed. send to results page with fail parameter.
            this.$router.push({ name: 'Results', params: {lesson: this.lesson, result: 'fail', questions: this.questions, uid: this.uid }})
          }
        }

        if (this.score == 100) {
          //success. send to results page with success parameter.
          this.$router.push({ name: 'Results', params: {lesson: this.lesson, result: 'pass', questions: this.questions, uid: this.uid }})
        }

        this.keyboardEntered = ""

        //next question
        this.questionOrder();

      },

      changeInput(){
        this.keyboardInput = !this.keyboardInput;

        if (this.keyboardInput) {
          if (this.question.substring(0,6) == "Choose") {
            console.log("YES");
            this.question = "Type" + this.question.substring(6)
          }
        } else if (!this.keyboardInput) {
          if (this.question.substring(0,4) == "Type") {
            console.log("YES");
            this.question = "Choose" + this.question.substring(4)
          }
        }
      },

      shuffle(array) {
        var currentIndex = array.length, randomIndex;

        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;

          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
      },





      //+------------------+
      //|  TASKS -- NOUNS  |
      //+------------------+

      //+----------------------------
      //| TASK 1
      //|  e.g. "Choose the word book in the prepositional case"
      //+----------------------------
      async chooseWordInCase() {

        this.russianSentence = ""
        this.english = ""

        var duplicates = 5

        while (duplicates == 5) { //if duplicates == 5, all are duplicates. So fetch again

          var randomCombination = this.selectRandomCombination();
          var caseConditional = randomCombination[0];
          var genderConditional = randomCombination[1];

          this.caseConditional = caseConditional;
          this.genderConditional = genderConditional;

          console.log("Find: " + caseConditional + ", " + genderConditional);

          var numberOfGenderedNouns;
          if (genderConditional == "m") {
            numberOfGenderedNouns = this.numberOfMasculineNouns;
          } else if (genderConditional == "f") {
            numberOfGenderedNouns = this.numberOfFeminineNouns;
          } else if (genderConditional == "n") {
            numberOfGenderedNouns = this.numberOfNeuterNouns;
          }

          var random = Math.floor((Math.random() * numberOfGenderedNouns));

          await db.collection("Nouns").where("genderID", "==", random).where("gender", "in", [genderConditional])
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                    this.word = doc.data();
                    this.correctAnswer = this.word[caseConditional];
                    console.log("Correct answer: " + this.correctAnswer);

                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

          var declensions = [['nom', this.word.nom], ['gen', this.word.gen], ['acc', this.word.acc], ['dat', this.word.dat], ['inst', this.word.inst], ['prep', this.word.prep]];
          duplicates = 0
          for (var i = 0; i < declensions.length; i++) {
            for (var j = 0; j < declensions.length; j++) {
              if ((declensions[i][1] == declensions[j][1]) && (i != j)) {
                declensions[i][1] = "DUPLICATE";
                duplicates++;
              }
            }
          }

        } //end of while duplicates == 5

        var shuffledButtons = [declensions[0][1], declensions[1][1], declensions[2][1], declensions[3][1], declensions[4][1], declensions[5][1]]
        this.shuffledButtons = this.shuffle(shuffledButtons);

        var cases = ['nom', 'acc', 'prep', 'dat', 'gen', 'inst'];
        var casesFullName = ['nominative', 'accusative', 'prepositional', 'dative', 'genitive', 'instrumental'];
        var caseRequired = casesFullName[cases.indexOf(caseConditional)]



        if (this.keyboardInput) {
          this.question = "Type the word " + this.word.english + " in the " + caseRequired + " case.";
        } else {
          this.question = "Choose the word " + this.word.english + " in the " + caseRequired + " case.";
        }
        // this.question = "Choose the word " + this.word.english + " in the " + caseRequired + " case.";
        this.russianSentence = ""

        this.loading = false;

      },



      //+----------------------------
      //| TASK 2
      //|  e.g. "Which sentence [out of 3 shown] is missing the word book in the accusative case?"
      //+----------------------------
      async whichSentenceIsMissing(){

        this.russianSentence = ""
        this.english = ""

        var wordID
        var empty = true;
        var cases = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep']
        while (empty) {

          var randomCombination = this.selectRandomCombination();
          var caseConditional = randomCombination[0];
          var genderConditional = randomCombination[1];
          this.caseConditional = caseConditional;
          this.genderConditional = genderConditional;

          console.log("Find: " + caseConditional + ", " + genderConditional);

          var collectionCaseArrays = ['NomTask2', 'GenTask2', 'AccTask2', 'DatTask2', 'InstTask2', 'PrepTask2']
          var collectionCaseName = collectionCaseArrays[cases.indexOf(caseConditional)]
          var count
          var collectionNameGender
          if (genderConditional == "m") {
            collectionNameGender = "MascTask2"
            count = this.task2countsMasc[collectionCaseArrays.indexOf(collectionCaseName)]
          } else if (genderConditional == "f") {
            collectionNameGender = "FemTask2"
            count = this.task2countsFem[collectionCaseArrays.indexOf(collectionCaseName)]
          } else if (genderConditional == "n") {
            collectionNameGender = "NeutTask2"
            count = this.task2countsNeut[collectionCaseArrays.indexOf(collectionCaseName)]
          }

          var random = Math.floor((Math.random() * count));

          await db.collection("Task2").doc("Stats").collection(collectionNameGender).doc("Stats").collection(collectionCaseName)
            .where("countID", "==", random)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {

                if(querySnapshot.empty) {
                  console.log("query empty");
                } else {
                  this.word = doc.data();
                  wordID = doc.id
                  empty = false;
                }

              });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

          } // while empty

          await db.collection("Nouns").doc(wordID)
              .get()
              .then((doc) => {
                if (doc.exists){
                  this.word = doc.data();
                } else {
                  console.log("No such document!");
                }}).catch((error) => {
                  console.log("Error getting document:", error);
                });

          var caseSentenceArrays = ['nomSentences', 'genSentences', 'accSentences', 'datSentences', 'instSentences', 'prepSentences']
          var fetchedSentenceIDs = []

          var correctSentenceArray = caseSentenceArrays[cases.indexOf(caseConditional)]

          var random2 = Math.floor((Math.random() * this.word[correctSentenceArray].length));
          fetchedSentenceIDs.push(this.word[correctSentenceArray][random2])

          //Now fetch 2 more random ones
          while (fetchedSentenceIDs.length < 3) {

            random = Math.floor((Math.random() * caseSentenceArrays.length));

            if (this.word[caseSentenceArrays[random]].length > 0) {
              random2 = Math.floor((Math.random() * this.word[caseSentenceArrays[random]].length));
              if (!fetchedSentenceIDs.includes(this.word[caseSentenceArrays[random]][random2])) {
                fetchedSentenceIDs.push(this.word[caseSentenceArrays[random]][random2])

                const index = caseSentenceArrays.indexOf(caseSentenceArrays[random]);
                if (index > -1) {
                  caseSentenceArrays.splice(index, 1);
                }
              }
            }

          }

          console.log(fetchedSentenceIDs);

          var sentences = []
          for (var i = 0; i < fetchedSentenceIDs.length; i++) {

            await db.collection("SentencesNouns").doc(fetchedSentenceIDs[i])
                .get()
                .then((doc) => {
                  if (doc.exists){
                    sentences[i] = doc.data();
                  } else {
                    console.log("No such document!");
                  }}).catch((error) => {
                    console.log("Error getting document:", error);
                  });

          }

          this.sentencesToFitWord[0] = sentences[0].russian
          this.sentencesToFitWord[1] = sentences[1].russian
          this.sentencesToFitWord[2] = sentences[2].russian
          this.correctAnswer = this.sentencesToFitWord[0]
          console.log("Correct answer: " + this.correctAnswer);
          this.sentencesToFitWord = this.shuffle(this.sentencesToFitWord);

          var caseNamesFull = ['nominative', 'genitive', 'accusative', 'dative', 'instrumental', 'prepositional']
          var caseReq = caseNamesFull[cases.indexOf(sentences[0].caseRequired)]
          this.caseConditional = sentences[0].caseRequired;
          this.question = "Which sentence is missing the word '" + this.word.english + "' in the " + caseReq + " case (" + this.word[sentences[0].caseRequired] + ")?"
          this.loading = false;

      },



      //+----------------------------
      //| TASK 3
      //|  presents sentence with gap; all 6 cases of the missing word
      //+----------------------------
      async chooseWordToFitSentence() {

        this.russianSentence = ""
        this.english = ""

        var duplicates = 5;
        while (duplicates == 5) {

          var foundQuestion = false;
          while (!foundQuestion) {

            var randomCombination = this.selectRandomCombination();
            var caseConditional = randomCombination[0];
            var genderConditional = randomCombination[1];

            this.caseConditional = caseConditional;
            this.genderConditional = genderConditional;

            console.log("Find: " + caseConditional + ", " + genderConditional);

            var genderAbbreviations = ['m', 'f', 'n']
            var genderedNounsCounts = [this.numberOfMasculineNouns, this.numberOfFeminineNouns, this.numberOfNeuterNouns]
            var numberOfGenderedNouns = genderedNounsCounts[genderAbbreviations.indexOf(genderConditional)]

            var random = Math.floor((Math.random() * numberOfGenderedNouns));
            var caseSentences = caseConditional + "Sentences"
            var chosenSentenceID = ""

            await db.collection("Nouns").where("gender", "==", genderConditional)
                                        .where("casePossibilities", "array-contains", caseConditional)
                                        .where("genderID", "==", random)
              .get()
              .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {

                      this.word = doc.data();
                      var caseArray = this.word[caseSentences]
                      random = Math.floor((Math.random() * caseArray.length));
                      chosenSentenceID = caseArray[random];
                      this.correctAnswer = this.word[caseConditional];

                  });
              })
              .catch((error) => {
                  console.log("Error getting documents: ", error);
              });

            if (chosenSentenceID != null && chosenSentenceID != "") {
              foundQuestion = true;
            }

          } //end of foundQuestion while loop



          // SHoudl this be await
          db.collection("SentencesNouns").doc(chosenSentenceID)
            .get()
            .then((doc) => {
              if (doc.exists){

                this.sentence = doc.data();
                this.russianSentence = this.sentence.russian

              } else {
                console.log("No such document!");
              }}).catch((error) => {
                console.log("Error getting document:", error);
              });

          var declensions = [['nom', this.word.nom], ['gen', this.word.gen], ['acc', this.word.acc], ['dat', this.word.dat], ['inst', this.word.inst], ['prep', this.word.prep]];

          duplicates = 0
          for (var i = 0; i < declensions.length; i++) {
            for (var j = 0; j < declensions.length; j++) {
              if ((declensions[i][1] == declensions[j][1]) && (i != j)) {
                declensions[i][1] = "DUPLICATE";
                duplicates++;
              }
            }
          }

        } //end of duplicates == 5 while loop

        var shuffledButtons = [declensions[0][1], declensions[1][1], declensions[2][1], declensions[3][1], declensions[4][1], declensions[5][1]]
        this.shuffledButtons = this.shuffle(shuffledButtons)


        if (this.keyboardInput) {
          this.question = "Type the word '" + this.word.english + "' in the case required to fit the sentence.";
        } else {
          this.question = "Choose the word '" + this.word.english + "' in the case required to fit the sentence.";
        }

        // this.question = "Choose the word '" + this.word.english + "' in the case required to fit the sentence.";
        this.englishTranslation = this.sentence.english
        this.russianSentence = ""

        console.log("Correct Answer: " + this.correctAnswer);

        this.loading = false;

      },



      //+----------------------------
      //| TASK 8 -- Special Nominative Noun Task (lesson 1)
      //|  presents sentence with gap; all 6 cases of the missing word
      //+----------------------------
      async identifyNounGender() {

        this.caseConditional = 'nom'

        var random = Math.floor((Math.random() * 3));
        var genders = ["masculine", "feminine", "neuter"]
        var genderAbbreviations = ["m", "f", "n"]
        this.genderConditional = genderAbbreviations[random]
        this.correctAnswer = genders[random]
        console.log("Correct Answer: " + this.correctAnswer);

        var genderedNounsCounts = [this.numberOfMasculineNouns, this.numberOfFeminineNouns, this.numberOfNeuterNouns]
        var numberOfGenderedNouns = genderedNounsCounts[genderAbbreviations.indexOf(this.genderConditional)]

        random = Math.floor((Math.random() * numberOfGenderedNouns));

        await db.collection("Nouns").where("gender", "==", this.genderConditional)
                                    .where("genderID", "==", random)
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  this.word = doc.data();
              });
          })
          .catch((error) => {
              console.log("Error getting documents: ", error);
          });

          this.question = "Which gender is the word '" + this.word.nom + "' (" + this.word.english + ")?";
          this.russianSentence = "";
          this.english = "";

          this.loading = false;

      },



      //+----------------------------
      //| TASK 4
      //|  e.g. "Is this sentence correct?" -- yes or no
      //+----------------------------
      async isTheSentenceCorrectNoun() {

        this.russianSentence = ""
        this.english = ""

        var duplicates = 5;
        while (duplicates == 5) {

          var foundQuestion = false;
          while (!foundQuestion) {

            var randomCombination = this.selectRandomCombination();
            var caseConditional = randomCombination[0];
            var genderConditional = randomCombination[1];

            this.caseConditional = caseConditional;
            this.genderConditional = genderConditional;

            console.log("Find: " + caseConditional + ", " + genderConditional);

            var genderAbbreviations = ['m', 'f', 'n']
            var genderedNounsCounts = [this.numberOfMasculineNouns, this.numberOfFeminineNouns, this.numberOfNeuterNouns]
            var numberOfGenderedNouns = genderedNounsCounts[genderAbbreviations.indexOf(genderConditional)]

            var random = Math.floor((Math.random() * numberOfGenderedNouns));
            var caseSentences = caseConditional + "Sentences"
            var chosenSentenceID = ""

            await db.collection("Nouns").where("gender", "==", genderConditional)
                                        .where("casePossibilities", "array-contains", caseConditional)
                                        .where("genderID", "==", random)
              .get()
              .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {

                      this.word = doc.data();
                      var caseArray = this.word[caseSentences]
                      random = Math.floor((Math.random() * caseArray.length));
                      chosenSentenceID = caseArray[random];
                      this.correctAnswer = this.word[caseConditional];

                  });
              })
              .catch((error) => {
                  console.log("Error getting documents: ", error);
              });

            if (chosenSentenceID != null && chosenSentenceID != "") {
              foundQuestion = true;
            }

          } //end of foundQuestion while loop





          await db.collection("SentencesNouns").doc(chosenSentenceID)
            .get()
            .then((doc) => {
              if (doc.exists){

                this.sentence = doc.data();
                // this.russianSentence = this.sentence.russian

              } else {
                console.log("No such document!");
              }}).catch((error) => {
                console.log("Error getting document:", error);
              });

          var declensions = [['nom', this.word.nom], ['gen', this.word.gen], ['acc', this.word.acc], ['dat', this.word.dat], ['inst', this.word.inst], ['prep', this.word.prep]];

          duplicates = 0
          for (var i = 0; i < declensions.length; i++) {
            for (var j = 0; j < declensions.length; j++) {
              if ((declensions[i][1] == declensions[j][1]) && (i != j)) {
                declensions[i][1] = "DUPLICATE";
                duplicates++;
              }
            }
          }

        } //end of duplicates == 5 while loop

        for (i = 0; i < declensions.length; i++) {
          if (declensions[i][0] == caseConditional) {
            declensions[i][1] = "DUPLICATE";
          }
        }

        random = Math.floor((Math.random() * 2));
        this.correctAnswer = (random == 0) ? 'yes' : 'no'

        console.log("Correct answer: " + this.correctAnswer);

        this.question = "Is this sentence correct?";
        this.russianSentence = this.sentence.russian

        var missingWord = this.word[caseConditional]

        for (i = declensions.length - 1; i >= 0; i--) {
          if (declensions[i][1] == "DUPLICATE") {
            declensions.splice(i, 1)
          }
        }

        if (this.correctAnswer == "no") {
          random = Math.floor((Math.random() * declensions.length));
          missingWord = this.word[declensions[random][0]]
        }

        this.russianSentence = this.russianSentence.replace("_", missingWord);
        this.russianSentence = this.russianSentence.substring(0, 1).toUpperCase() + this.russianSentence.substring(1)

        this.loading = false;

      },





      //+-----------------------+
      //|  TASKS -- ADJECTIVES  |
      //+-----------------------+

      //+------------------+
      //| TASK 5
      //|  e.g. "Choose the word in the masculine accusative animate case"
      //+------------------+
      async chooseCorrectAdjectiveForm() {

        var randomCombination = this.selectRandomCombinationAdjective();
        var caseConditional = randomCombination[0];
        var typeConditional = randomCombination[1];

        console.log("Find: " + caseConditional + ", " + typeConditional);

        this.caseConditional = caseConditional;
        this.genderConditional = typeConditional;

        //+------------------+
        //| Find a random word
        //+------------------+
        var found = false;
        while (!found) {

          var random = Math.floor((Math.random() * this.numberOfAdjectives));

          await db.collection("Adjectives").where("adjectiveID", "==", random).where("incomplete", "in", [false])
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                  if(querySnapshot.empty) {
                    console.log("query empty");
                  } else {
                    this.word = doc.data();
                    found = true;
                  }

                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

          } //end: word has been found

          var formArrays = ["mascForms", "femForms", "neutForms", "pluForms"]
          var cases = ['nom', 'gen', 'accI', 'accA', 'dat', 'inst', 'prep']
          var types = ['m', 'f', 'n', 'p']

          var formArrayChosen = formArrays[types.indexOf(typeConditional)]
          var caseChosen = caseConditional;
          if (caseChosen == 'acc') {
            random = Math.floor((Math.random() * 2));
            caseChosen = (random == 0 ? 'accA' : 'accI')
          }

          var correct = this.word[formArrayChosen][cases.indexOf(caseChosen)]

          var chosenForms = []
          chosenForms.push(correct)

          while (chosenForms.length < 6) {

            random = Math.floor((Math.random() * 4));
            var random2 = Math.floor((Math.random() * 7));

            if (! chosenForms.includes(this.word[formArrays[random]][random2])) {
              chosenForms.push(this.word[formArrays[random]][random2])
            }

          }

          this.correctAnswer = correct;
          console.log("CORRECT ANSWER: " + correct);

          this.shuffledButtons = this.shuffle(chosenForms)

          var caseNamesFull = ['nominative', 'genitive', 'accusative (animate)', 'accusative (inanimate)', 'dative', 'instrumental', 'prepositional']
          var typeNamesFull = ['masculine', 'feminine', 'neuter', 'plural']

          if (this.keyboardInput) {
            this.question = "Type the word '" + this.word.english + "' in the " + caseNamesFull[cases.indexOf(caseChosen)] + ", " + typeNamesFull[types.indexOf(typeConditional)] + " form.";
          } else {
            this.question = "Choose the word '" + this.word.english + "' in the " + caseNamesFull[cases.indexOf(caseChosen)] + ", " + typeNamesFull[types.indexOf(typeConditional)] + " form.";
          }

          // this.question = "Choose the word '" + this.word.english + "' in the " + caseNamesFull[cases.indexOf(caseChosen)] + ", " + typeNamesFull[types.indexOf(typeConditional)] + " form.";
          this.russianSentence = ""
          this.english = ""

          this.loading = false;

      },



      //+------------------+
      //| TASK 6
      //|  e.g. "Я вижу _ человек" -> randomly select options, include correct case but in other gender form.
      //+------------------+
      async chooseAdjectiveToFitSentence() {

        //+------------------+
        //| Find a random sentence
        //+------------------+
        var found = false;
        while (!found) {

          var randomCombination = this.selectRandomCombinationAdjective();
          var caseConditional = randomCombination[0];
          var typeConditional = randomCombination[1];

          console.log("Find: " + caseConditional + ", " + typeConditional);

          this.caseConditional = caseConditional;
          this.genderConditional = typeConditional;

          var random = Math.floor((Math.random() * this.numberOfSentencesAdjectives));
          var types = ['masc', 'fem', 'neut', 'plu']
          var typeInitials = ['m', 'f', 'n', 'p']
          var typeToSearch = types[typeInitials.indexOf(typeConditional)]

          if (caseConditional == 'acc') {
            var randomAcc = Math.floor((Math.random() * 2));
            caseConditional = (randomAcc == 0 ? 'accA' : 'accI')
          }

          console.log("Find: " + random + ", " + caseConditional + ", " + typeToSearch);

          await db.collection("SentencesAdjectives")
            .where("sentenceID", "==", random).where("caseRequired", "in", [caseConditional]).where("type", "array-contains", typeToSearch)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {

                if(querySnapshot.empty) {
                  console.log("query empty");
                } else {
                  this.sentence = doc.data();
                  found = true;
                }

              });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

        } //end while: sentence has been found

        found = false;
        while (!found) {

          random = Math.floor((Math.random() * this.sentence.words.length));
          var chosenWord = this.sentence.words[random]

          await db.collection("Adjectives").where("english", "==", chosenWord).where("incomplete", "in", [false])
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                  if(querySnapshot.empty) {
                    console.log("query empty");
                  } else {
                    this.word = doc.data();
                    found = true;
                  }

                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

        } //end while, word has been found


        var formArrays = ["mascForms", "femForms", "neutForms", "pluForms"]
        var cases = ['nom', 'gen', 'accI', 'accA', 'dat', 'inst', 'prep']
        types = ['m', 'f', 'n', 'p']

        var formArrayChosen = formArrays[types.indexOf(typeConditional)]
        var caseChosen = caseConditional;
        if (caseChosen == 'acc') {
          random = Math.floor((Math.random() * 2));
          caseChosen = (random == 0 ? 'accA' : 'accI')
        }

        this.correctAnswer = this.word[formArrayChosen][cases.indexOf(caseChosen)]
        var chosenForms = []
        chosenForms.push(this.correctAnswer)

        while (chosenForms.length < 6) {

          random = Math.floor((Math.random() * 4));
          var random2 = Math.floor((Math.random() * 7));

          if (! chosenForms.includes(this.word[formArrays[random]][random2])) {
            chosenForms.push(this.word[formArrays[random]][random2])
          }

        }

        console.log("CORRECT ANSWER: " + this.correctAnswer);
        this.shuffledButtons = this.shuffle(chosenForms);


        if (this.keyboardInput) {
          this.question = 'Type the correct form of the word "' + this.word.english + '" to fit the sentence.';
        } else {
          this.question = 'Choose the correct form of the word "' + this.word.english + '" to fit the sentence.';
        }

        // this.question = 'Choose the correct form of the word "' + this.word.english + '" to fit the sentence.';
        this.russianSentence = this.sentence.russian;
        this.english = this.sentence.english;

        this.loading = false;

      },



      //+----------------------------
      //| TASK 7
      //|  e.g. "Is this sentence correct?" -- yes or no
      //+----------------------------
      async isTheSentenceCorrectAdjective() {

        console.log("adjective");

        this.russianSentence = ""
        this.english = ""

        //+------------------+
        //| Find a random sentence
        //+------------------+
        var found = false;
        while (!found) {

          var randomCombination = this.selectRandomCombinationAdjective();
          var caseConditional = randomCombination[0];
          var typeConditional = randomCombination[1];

          // console.log("Find: " + caseConditional + ", " + typeConditional);

          this.caseConditional = caseConditional;
          this.genderConditional = typeConditional;

          var random = Math.floor((Math.random() * this.numberOfSentencesAdjectives));
          var types = ['masc', 'fem', 'neut', 'plu']
          var typeInitials = ['m', 'f', 'n', 'p']
          var typeToSearch = types[typeInitials.indexOf(typeConditional)]

          // console.log(typeToSearch);

          if (caseConditional == 'acc') {
            var randomAcc = Math.floor((Math.random() * 2));
            caseConditional = (randomAcc == 0 ? 'accA' : 'accI')
          }

          console.log("Find: " + random + ", " + caseConditional + ", " + typeToSearch);

          await db.collection("SentencesAdjectives")
            .where("sentenceID", "==", random).where("caseRequired", "in", [caseConditional]).where("type", "array-contains", typeToSearch)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {

                if(querySnapshot.empty) {
                  console.log("query empty");
                } else {
                  this.sentence = doc.data();
                  found = true;
                }

              });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

        } //end while: sentence has been found

        found = false;
        while (!found) {

          random = Math.floor((Math.random() * this.sentence.words.length));
          var chosenWord = this.sentence.words[random]

          await db.collection("Adjectives").where("english", "==", chosenWord).where("incomplete", "in", [false])
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                  if(querySnapshot.empty) {
                    console.log("query empty");
                  } else {
                    this.word = doc.data();
                    found = true;
                  }

                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

        } //end while, word has been found


        var caseAbbreviations = ['nom', 'gen', 'accA', 'accI', 'dat', 'inst', 'prep']
        console.log(this.sentence.english);
        console.log(this.word.english);
        var formsArray = this.sentence.type[0] + "Forms"
        var correctForm = this.word[formsArray][caseAbbreviations.indexOf(this.sentence.caseRequired)]

        random = Math.floor((Math.random() * 2));
        this.correctAnswer = (random == 0) ? 'yes' : 'no'
        console.log("Correct answer: " + this.correctAnswer);

        this.question = "Is this sentence correct?";
        this.russianSentence = this.sentence.russian

        if (this.correctAnswer == "no") {
          var formArrays = ["mascForms", "femForms", "neutForms", "pluForms"]
          var wrongForm = ""
          while (wrongForm == "") {
            random = Math.floor((Math.random() * 4));
            var random2 = Math.floor((Math.random() * 7));
            if (this.word[formArrays[random]][random2] != correctForm) {
              wrongForm = this.word[formArrays[random]][random2]
            }
          }
          this.russianSentence = this.russianSentence.replace("_", wrongForm);
          this.russianSentence = this.russianSentence.substring(0, 1).toUpperCase() + this.russianSentence.substring(1)
        } else {
          this.russianSentence = this.russianSentence.replace("_", correctForm);
          this.russianSentence = this.russianSentence.substring(0, 1).toUpperCase() + this.russianSentence.substring(1)
        }


        this.loading = false;

      },








      chooseTaskOrder() {
        var practiceOrder, random
        if (this.lesson == 'nom1' || this.lessonsCompleted[0] == 0) {
          // IDENTIFY GENDER LESSON
          this.taskOrder = [6]
        } else if (this.questionPossibilitiesAdjectives.length == 0 || this.lesson == 'nom2') {
          // NOUN LESSON
          practiceOrder = [[1, 3, 2, 7],
                           [2, 7, 1, 3],
                           [3, 7, 1, 2],
                           [2, 1, 7, 3]]
          random = Math.floor((Math.random() * practiceOrder.length));
          this.taskOrder = practiceOrder[random]
        } else if (this.questionPossibilities.length == 0 || this.lesson == 'nom3') {
          // ADJECTIVE LESSON
          practiceOrder = [[5, 4, 5, 8],
                           [5, 5, 4, 8],
                           [5, 4, 8, 5]]
          random = Math.floor((Math.random() * practiceOrder.length));
          this.taskOrder = practiceOrder[random]
        } else {
          // PRACTICE ALL
          practiceOrder = [[1, 2, 3, 4, 5, 6, 7, 8],
                           [2, 7, 3, 1, 6, 4, 5, 8],
                           [7, 5, 2, 6, 1, 8, 3, 4],
                           [4, 8, 1, 3, 6, 7, 2, 5]]
          random = Math.floor((Math.random() * practiceOrder.length));
          this.taskOrder = practiceOrder[random]
        }
      },


      questionOrder(){



        console.log("--------------");

        if (this.questionNumber == this.taskOrder.length && this.score < 100) {
          this.questionNumber = 0
        }

        if (this.taskOrder[this.questionNumber] == 1) {
          this.taskType = "1"
          this.chooseWordInCase();
        } else if (this.taskOrder[this.questionNumber] == 2) {
          this.taskType = "2"
          this.whichSentenceIsMissing();
        } else if (this.taskOrder[this.questionNumber] == 3) {
          this.taskType = "3"
          this.chooseWordToFitSentence();
        } else if (this.taskOrder[this.questionNumber] == 4) {
          this.taskType = "4"
          this.chooseCorrectAdjectiveForm();
        } else if (this.taskOrder[this.questionNumber] == 5) {
          this.taskType = "5"
          this.chooseAdjectiveToFitSentence();
        } else if (this.taskOrder[this.questionNumber] == 6) {
          this.taskType = "6"
          this.identifyNounGender();
        } else if (this.taskOrder[this.questionNumber] == 7) {
          this.taskType = "7"
          this.isTheSentenceCorrectNoun();
        } else if (this.taskOrder[this.questionNumber] == 8) {
          this.taskType = "8"
          this.isTheSentenceCorrectAdjective();
        } else {
          console.log("else");
        }

        console.log("TASK: " + this.taskType);

        this.questionNumber++

      },





      async loadData() {

        var doc = await db.collection('Users').doc(this.uid).get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          var user = doc.data();
          this.lessonsCompleted = user.lessonsCompleted;
          this.lessonsCompletedAdjectives = user.lessonsCompletedAdjectives;
        }

        var cases = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst']
        var caseInitials = ['n', 'a', 'p', 'g', 'd', 'i']
        var genderInitials = ['m', 'f', 'n']

        if (this.lesson == null) {
          //GENERAL PRACTICE MODE
          //NOUNS
          // if (this.lessonsCompleted[0] == 3) {
          //   this.questionPossibilities.push([['nom', "m"], ['nom', "f"], ['nom', "n"]])
          // }

          for (var i = 1; i < this.lessonsCompleted.length; i++) {
            if (this.lessonsCompleted[i] == 1) {
              this.questionPossibilities.push([[cases[i], "m"]])
            } else if (this.lessonsCompleted[i] == 2) {
              this.questionPossibilities.push([[cases[i], "m"], [cases[i], "f"]])
            } else if (this.lessonsCompleted[i] == 3) {
              this.questionPossibilities.push([[cases[i], "m"], [cases[i], "f"], [cases[i], "n"]])
            }
          }


          //ADJECTIVES
          if (this.lessonsCompletedAdjectives[0] == 4) {
            this.questionPossibilitiesAdjectives.push([["nom", "m"], ["nom", "f"], ["nom", "n"], ["nom", "p"]])
          }

          for (i = 1; i < this.lessonsCompletedAdjectives.length; i++) {
            if (this.lessonsCompletedAdjectives[i] == 1) {
              this.questionPossibilitiesAdjectives.push([[cases[i], "m"]])
            } else if (this.lessonsCompletedAdjectives[i] == 2) {
              this.questionPossibilitiesAdjectives.push([[cases[i], "m"], [cases[i], "f"]])
            } else if (this.lessonsCompletedAdjectives[i] == 3) {
              this.questionPossibilitiesAdjectives.push([[cases[i], "m"], [cases[i], "f"], [cases[i], "n"]])
            } else if (this.lessonsCompletedAdjectives[i] == 4) {
              this.questionPossibilitiesAdjectives.push([[cases[i], "m"], [cases[i], "f"], [cases[i], "n"], [cases[i], "p"]])
            }
          }

        } else {
          //LESSON MODE

          var practiceAll = ['allNom', 'allAcc', 'allPrep', 'allGen', 'allDat', 'allInst']

          if (practiceAll.includes(this.lesson)) {
            //PRACTICE ALL [case] MODE

            //push all completed lessons from this one case.
            var lessonsInCase = this.lessonsCompleted[practiceAll.indexOf(this.lesson)]
            if (lessonsInCase == 1) {
              this.questionPossibilities.push([[cases[practiceAll.indexOf(this.lesson)], "m"]])
            } else if (lessonsInCase == 2) {
              this.questionPossibilities.push([[cases[practiceAll.indexOf(this.lesson)], "m"], [cases[practiceAll.indexOf(this.lesson)], "f"]])
            } else if (lessonsInCase == 3) {
              this.questionPossibilities.push([[cases[practiceAll.indexOf(this.lesson)], "m"], [cases[practiceAll.indexOf(this.lesson)], "f"], [cases[practiceAll.indexOf(this.lesson)], "n"]])
            }

            var lessonsInCaseAdj = this.lessonsCompletedAdjectives[practiceAll.indexOf(this.lesson)]
            if (lessonsInCaseAdj == 1) {
              this.questionPossibilitiesAdjectives.push([[cases[practiceAll.indexOf(this.lesson)], "m"]])
            } else if (lessonsInCaseAdj == 2) {
              this.questionPossibilitiesAdjectives.push([[cases[practiceAll.indexOf(this.lesson)], "m"], [cases[practiceAll.indexOf(this.lesson)], "f"]])
            } else if (lessonsInCaseAdj == 3) {
              this.questionPossibilitiesAdjectives.push([[cases[practiceAll.indexOf(this.lesson)], "m"], [cases[practiceAll.indexOf(this.lesson)], "f"], [cases[practiceAll.indexOf(this.lesson)], "n"]])
            } else if (lessonsInCaseAdj == 4) {
              this.questionPossibilitiesAdjectives.push([[cases[practiceAll.indexOf(this.lesson)], "m"], [cases[practiceAll.indexOf(this.lesson)], "f"], [cases[practiceAll.indexOf(this.lesson)], "n"], [cases[practiceAll.indexOf(this.lesson)], "p"]])
            }


          } else {

            var nounLessons = ['1', '3', '5']
            var lessonIsNoun = (nounLessons.includes(this.lesson.substring((this.lesson.length - 1), this.lesson.length))) ? true : false;

            var letter = this.lesson.substring(0,1);
            var casePush = cases[caseInitials.indexOf(letter)]
            var lastNumber = this.lesson.substring((this.lesson.length - 1), this.lesson.length);

            if (casePush == 'nom') {

              if (lastNumber == '2') {
                //Nom nouns
                this.questionPossibilities.push([["nom", "m"], ["nom", "f"], ["nom", "n"]])

              } else if (lastNumber == '3') {
                //Nom adjectives
                this.questionPossibilitiesAdjectives.push([["nom", "m"], ["nom", "f"], ["nom", "n"], ["nom", "p"]])

              }

            } else if (lessonIsNoun) {
              //NOUN

              letter = this.lesson.substring(0,1);
              casePush = cases[caseInitials.indexOf(letter)]
              var length = this.lesson.length;
              lastNumber = this.lesson.substring((length - 1), length);
              lastNumber = nounLessons.indexOf(lastNumber);
              var genderPush = genderInitials[lastNumber];
              this.questionPossibilities.push([[casePush, genderPush]])

            } else {
              //ADJECTIVE

              var typeInitials = ["m", "f", "n", "p"]
              var adjLessons = ['2', '4', '6', '7']

              letter = this.lesson.substring(0,1);
              casePush = cases[caseInitials.indexOf(letter)]
              length = this.lesson.length;
              lastNumber = this.lesson.substring((length - 1), length);
              var typePush = typeInitials[adjLessons.indexOf(lastNumber)];
              this.questionPossibilitiesAdjectives.push([[casePush, typePush]])

            }
          }
        }

        doc = await db.collection('Statistics').doc('STATISTICS').get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          var stats = doc.data();
          this.numberOfNouns = stats.numberOfNouns;
          this.numberOfSentencesNouns = stats.numberOfSentencesNouns;
          this.numberOfMasculineNouns = stats.numberOfMasculineNouns;
          this.numberOfFeminineNouns = stats.numberOfFeminineNouns;
          this.numberOfNeuterNouns = stats.numberOfNeuterNouns;
          this.numberOfSentencesAdjectives = stats.numberOfSentencesAdjectives;
          this.numberOfAdjectives = stats.numberOfAdjectives;
          this.task2countsMasc = stats.task2countsMasc;
          this.task2countsFem = stats.task2countsFem;
          this.task2countsNeut = stats.task2countsNeut;
        }

        this.chooseTaskOrder()
        this.questionOrder()
      }


    },
    async beforeMount(){

      // this.loading = true;

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          this.user = user;
          this.uid = user.uid;
          this.username = user.displayName;
          this.signedIn = true

          this.loadData()

        } else {
          // User is signed out
          // ...
          this.signedIn = false;
          this.$router.push({ name: 'Index'})
        }
      });







    }
  }

</script>














<!--  -->
